// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agreeable-js": () => import("./../../../src/pages/agreeable.js" /* webpackChunkName: "component---src-pages-agreeable-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coding-js": () => import("./../../../src/pages/coding.js" /* webpackChunkName: "component---src-pages-coding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lateness-js": () => import("./../../../src/pages/lateness.js" /* webpackChunkName: "component---src-pages-lateness-js" */),
  "component---src-pages-luck-js": () => import("./../../../src/pages/luck.js" /* webpackChunkName: "component---src-pages-luck-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */)
}

